






import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'

@Component
export default class PreviewDialog extends Vue {
  @PropSync('visible', { default: false }) syncedVisible: boolean
  @Prop() url: string
}
