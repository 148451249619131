
































import { actionTypes } from '@/store'
import { tag } from '@/utils/options'
import { Vue, Component } from 'vue-property-decorator'

type Tag = AnyObj

@Component({ components: {} })
export default class PassTagDialog extends Vue {
  private visible = false
  private id = ''
  private formData: { tagIds: string[] } = { tagIds: [] }
  private loading = false
  private deviceId = ''

  get tagOptions() {
    // 默认选中的tag
    return tag.types.map(({ value, label }) => ({
      value,
      label,
      children: this.tags
        .filter(({ type }) => type === value)
        .map(({ id, name }) => ({ value: id, label: name }))
    }))
  }
  get tags(): Tag[] {
    return this.$store.state.tags
  }
  async getAllTags() {
    // 获取所有tag
    const resp: AnyObj = await this.$api.base.getContrastList({ limit: 300 })
    if (resp.data && resp.data.data) {
      return resp.data.data
    }
    return []
  }
  async getCountryTags(countryId: string) {
    // 获取村下的tag
    const { data } = await this.$api.europa.getArea(countryId)
    if (data.data) {
      return data.data.tags
    }
    return []
  }

  public async open(value: AnyObj) {
    await this.$store.dispatch(actionTypes.FETCH_TAGS, { refresh: true })
    const countryTags = await this.getCountryTags(value.countryId)
    const tagIds = countryTags.map((i: AnyObj) => i.id)
    this.visible = true
    this.deviceId = value.deviceId
    this.$set(this.formData, 'tagIds', tagIds)
  }
  private close() {
    this.$confirm('取消后将不会为当前设备配置任何标签，您确定要取消吗?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '返回',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    })
      .then(() => {
        this.visible = false
      })
      .catch(() => {})
  }
  private async submit() {
    const { tagIds } = this.formData
    if (tagIds.length === 0) {
      this.$message({ message: '至少选择一个标签进行绑定', type: 'error' })
      return
    }
    this.loading = true
    const { data } = await this.$api.base.postDeviceTags(this.deviceId, { tagIds })
    if (data && data.code === 0) {
      this.visible = false
      this.$message({ message: '绑定成功', type: 'success' })
    }
    this.loading = false
  }
}
