
































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import InputSearch from '@/components/common/InputSearch.vue'
import Modal from '@/components/common/Modal'
import DeviceDrawer from './DeviceDrawer.vue'
import _ from 'lodash'
import { blobToJson, is } from '@/utils/helpers'
import { ElTable } from 'element-ui/types/table'
import PreviewDialog from '../../RegionManagement/EquipPage/PreviewDialog.vue'
import DeviceInfo from '../../RegionManagement/EquipPage/deviceInfo.vue'
import PassTagDialog from '@/components/dashboard/PassTagDialog/index.vue'
import treeFind from '@/libs/operation-tree-node/treeFind'

const drawer = Modal(DeviceDrawer)

@Component({ components: { InputSearch, DeviceInfo, PassTagDialog } })
export default class EquipmentList extends Vue {
  @Prop({ default: () => ({}) }) area: AnyObj
  @Prop({ default: () => [] }) areaList: AnyObj[]

  @Ref('table') table: ElTable
  @Ref() readonly DeviceInfoNode: DeviceInfo
  @Ref() readonly PassTagNode: PassTagDialog

  filterData: AnyObj = {}
  pagination = { pageSize: 20, currentPage: 1 }
  loading = false
  equipmentList: AnyObj[] = []
  equipmentTotal = 0
  selection: AnyObj = []
  private previewDialog = Modal(PreviewDialog)
  private previewLoading: Record<string, boolean> = {}
  get params() {
    const { currentPage, pageSize } = this.pagination

    return {
      areaIds: [this.area.id],
      cascade: true,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      ..._.pickBy(this.filterData, is.ava)
    }
  }

  @Watch('area')
  watchArea(newVal: any) {
    if (newVal.id) {
      this.fetchEquipmentList()
    }
  }

  @Watch('pagination', { deep: true })
  async watchPagination() {
    await this.fetchEquipmentList()

    if (this.table) {
      this.table.bodyWrapper.scrollTop = 0
    }
  }
  showBindTagMessage(data: AnyObj) {
    this.$msgbox({
      message: (
        <div>
          <h2 class="title">设备创建成功！</h2>

          <p class="hint">
            该设备如果是通行设备（面板）则需要点击“配置通行标签”按钮为该设备进行标签配置，配置完成后标签即可下发到相应设备上
            <br />
            如果是其他类型设备请点击“忽略”按钮
          </p>
        </div>
      ),
      center: true,
      showClose: false,
      showCancelButton: true,
      confirmButtonText: '配置通行标签',
      cancelButtonText: '忽略',
      customClass: 'account-message-box',
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          const area = treeFind(this.areaList, ({ id }) => data.areaId === id)
          instance.confirmButtonLoading = true
          // 传递村id和创建的设备的id
          await this.PassTagNode.open({ countryId: area?.countryId, deviceId: data.id })
          done()
          instance.confirmButtonLoading = false
        } else {
          done()
        }
      }
    }).catch(() => {})
  }

  created() {
    drawer.on(['updated'], () => this.fetchEquipmentList())
    drawer.on(['created'], (data: any) => {
      this.fetchEquipmentList()
      this.showBindTagMessage(data)
    })
  }

  deleteEquipment(row: AnyObj) {
    this.$confirm('即将删除设备，删除后无法恢复，是否确定删除？', {
      type: 'warning',
      loading: true,
      onConfirm: async () => {
        const { data }: any = await this.$api.base.deleteDevice(row.id)
        if (!data?.error) {
          this.$message({ message: '删除成功', type: 'success' })
          await this.fetchEquipmentList()
        }
      }
    }).catch(() => {})
  }

  private dateFormat(row: any, column: AnyObj) {
    const date = row[column.property]
    if (!date) {
      return '-'
    }
    return this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
  }

  private getAreaName(areaId: any) {
    const area = treeFind(this.areaList, ({ id }) => areaId === id)

    return area ? area.name : ''
  }

  fetchAreas() {
    this.$store.dispatch('areas/fetchAreas')
  }

  async fetchEquipmentList() {
    this.loading = true

    const { data } = await this.$api.base.getDevicesSearch(this.params)

    this.loading = false

    if (data.code === 0) {
      this.equipmentList = data.data
      this.equipmentTotal = data.count
    }
  }

  filter() {
    this.pagination.currentPage = 1
    this.fetchEquipmentList()
  }

  searchInputChange(value: string) {
    this.filterData = { ...this.filterData, physicalId: value }
    this.filter()
  }

  private clickSeeDevice({ physicalId }: AnyObj) {
    this.DeviceInfoNode.open({ physicalId })
  }

  addEquipment() {
    drawer.open({ mode: 'create', data: { areaId: this.area.id } })
  }

  editEquipment(equipment: AnyObj) {
    drawer.open({ mode: 'update', data: equipment })
  }

  private setPreviewLoading(id: string, loading: boolean) {
    this.previewLoading = { ...this.previewLoading, [id]: loading }
  }

  private async clickPreview(row: any) {
    this.setPreviewLoading(row.id, true)

    const { data } = await this.$api.base.getDevicePreview(row.id)

    this.setPreviewLoading(row.id, false)

    if (data.code) {
      this.$message({ message: data.error?.description, type: 'error' })
    } else if (data.type === 'application/json') {
      const { error } = await blobToJson(data)
      this.$message({ message: error?.description, type: 'error' })
    } else if (!data.error) {
      const url = URL.createObjectURL(data)

      this.previewDialog.open({ url })
    }
  }

  viewEquipment(equipment: AnyObj) {
    this.$router.push({ name: '设备信息查看', params: { id: equipment.id } })
  }

  selectionChange(selection: AnyObj[]) {
    this.selection = selection
  }
}
