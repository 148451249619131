
















import { Vue, Component, Watch } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import Areas from '@/components/common/Areas/index.vue'
import DeviceList from './DeviceList/index.vue'

@Component({ components: { PageTitle, Areas, DeviceList } })
export default class EquipmentManagement extends Vue {
  areaList: AnyObj[] = []
  currentArea: AnyObj = {}
  get isChildRoute() {
    return this.$route.name !== '设备管理'
  }

  get areas() {
    return this.$store.state.areas.areas
  }

  async mounted() {
    this.setArea()
  }

  @Watch('areas')
  watchArea() {
    this.setArea()
  }
  setArea() {
    this.areaList = this.areas
  }

  areaChange(area: AnyObj) {
    this.currentArea = area
  }
}
