

















































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import { ElForm } from 'element-ui/types/form'
import _ from 'lodash'
import { is } from '@/utils/helpers'
import treeFind from '@/libs/operation-tree-node/treeFind'

@Component({ components: { FormDrawer } })
export default class EquipmentDrawer extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: 'create' }) mode: 'create' | 'update'
  @Prop({ default: () => ({}) }) data: AnyObj

  @Ref('form') form: ElForm

  formData: AnyObj = {
    name: '',
    geography: '',
    physicalId: ''
  }
  location = { lng: '', lat: '' }
  loading = false

  get rules() {
    return {
      name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
      areaId: [{ required: true, message: '请选择所属区域', trigger: 'blur' }],
      physicalId: [
        { required: this.isCreateMode, message: '请输入硬件编码', trigger: 'blur' },
        { min: 1, max: 40, message: '硬件编码应在40字符内，请重新输入', trigger: 'blur' }
      ]
    }
  }

  get isCreateMode() {
    return this.mode === 'create'
  }

  get title() {
    return this.isCreateMode ? '添加设备' : '编辑设备'
  }

  get areaList(): AnyObj[] {
    return this.$store.state.areas.areas
  }

  @Watch('location', { deep: true })
  watchLocation() {
    const { lng, lat } = this.location

    const coordinates = lng && lat ? lng + ',' + lat : null
    this.formData.coordinates = coordinates
  }

  mounted() {
    this.initialize()
  }

  initialize() {
    if (this.data) {
      const { name, areaId, physicalId, coordinates, description } = this.data

      this.formData = { name, physicalId, coordinates, description }

      const area = treeFind(this.areaList, ({ id }) => id === areaId)
      if (area && !area.children) {
        this.formData = { ...this.formData, areaId }
      }

      if (coordinates) {
        const [lng, lat] = coordinates.split(',')

        this.location = { lng, lat }
      }
    }
  }

  close() {
    this.form.resetFields()
    this.formData = {}
    this.location = { lng: '', lat: '' }
    this.visible = false
  }

  submit() {
    this.form.validate(async valid => {
      if (valid) {
        this.loading = true

        const omitParams = this.isCreateMode ? [] : ['physicalId']
        const params = _.omit(_.pickBy(this.formData, is.ava), omitParams)
        if (!this.isCreateMode) {
          for (const key in this.formData) {
            if (!(key in params) && key != 'physicalId') {
              this.$set(params, key, null)
            }
          }
        }
        const create = () => this.$api.base.addDevice(params)
        const update = () => this.$api.europa.updateDevice(this.data.id, params)

        const { data } = await (this.mode === 'create' ? create() : update())
        this.loading = false

        if (data.code === 0) {
          if (this.isCreateMode || this.formData.areaId !== this.data.areaId) {
            this.$store.dispatch('areas/fetchAreas')
          }

          this.$message({
            message: `${this.mode === 'create' ? '新建' : '编辑'}成功`,
            type: 'success'
          })
          this.$emit(this.mode === 'create' ? 'created' : 'updated', data.data)
          this.close()
        }
      }
    })
  }
}
